import { useState, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function useDialog() {
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const CloseButton = () => (
    <IconButton
      onClick={handleClose}
      sx={{
        position: 'absolute',
        top: 'var(--mui-spacing)',
        right: 'var(--mui-spacing)',
      }}
    >
      <CloseIcon />
    </IconButton>
  );

  return {
    handleClick,
    handleClose,
    CloseButton,
    setOpen,
    open,
  };
}
